@import "_logged_out_common";

.login-options {
	margin-bottom: 30px;
	overflow: hidden;

	h4 {
		float: left;
		font-weight: 600;
		font-size: 15px; 
		color: #7d91aa !important;
	}

}

.login .content {
	padding-bottom: 0px;
}

.login .content .form-actions .btn {
	margin-top: 1px;
}

.login .content .form-actions .btn-success {
	font-weight: 600;
	padding: 10px 20px !important; 
}

.login .content .form-actions .btn-default {
	font-weight: 600;
	padding: 10px 25px !important; 
	color: #6c7a8d;
	background-color: #ffffff;
	border: none;
}

.login .content .form-actions .btn-default:hover {
	background-color: #fafaff;
	color: #45b6af;
}

.login .content .forget-password {
	font-size: 14px;
	float: right;
	display: inline-block; 
	margin-top: 10px;
}

.login .content .check {
	color: #8290a3;
}

.login .content .rememberme {
	margin-left: 8px;
	margin-top: 10px;
}

.login .content .login-account-action {	 
	// margin: 0 -40px -40px -40px;
	padding: 14px 0 11px 0;
	text-align: center;
	
	@include border-radius(0 0 7px 7px);
}

.login-other-options {
	margin-left: -30px;
	margin-right: -30px;
}





.login .content .create-account {
	background-color: #6c7a8d;	
}

.login .content .continue-guest {
	background-color: #6C8D70;
}

.login .content .login-account-action:last-child {
	// margin-bottom: -30px;
}




.login .content .login-account-action p a { 
	font-weight: 600;
	font-size: 14px; 
	color: #c3cedd;
}

.login .content .login-account-action a {
	display: inline-block;
	margin-top: 5px;
}




@media (max-width: 440px) {

	.forget-password {
	display: inline-block;
	margin-top: 20px; 
	}	
	
	.login .checkbox {
	font-size: 13px;
	}
	
}



#incorrect-pw, #incorrect-role {
	top: -12px;
	position: relative;
	// color: red;
	font-weight: bold;
	font-size: 14px;

	display: none;

	.red {
		color: red;
	}
}

#version-number-contain {
	display: block;
	width: 100%;
	max-width: 400px;
	text-align: right;
	margin-left: auto;	
	margin-right: auto;	
}

#version-number {
	margin-top: -5px;
	margin-right: 0px;
	// display: inline-block;
	// width: 400px;
}

#iedu-copyright {
	float: left;
	color: white;
	margin-top: -5px;
	margin-left: 0px;
}

#iedu-logo-contain {
	background-image: url(/static/img/logo-white-text-small.png);
	height: 76px;
	display: block;
	width: 100%;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 9px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}


@import "_logged_out_logo";