// Used for public pages including login, register, forgot password


@import '../global/_variables'; // global variables.
@import '../global/components/_mixins'; // global mixings.

$bg-color: #364150;

.logged-out {

	background-color: $bg-color !important;

	.logo {
		margin: 0 auto;
		margin-top: 60px;
		padding: 15px;
		text-align: center;

		width: 100%;
		max-width: 400px;

	}

	.content {

		background-color: #eceef1;
		@include border-radius(7px);
		width: 400px;
		margin: 40px auto 10px auto;
		padding: 30px;
		padding-top: 10px;
		overflow: hidden;
		position: relative;

		h3 {
			color: #4db3a5;
			text-align: center; 
			font-size: 28px;
			font-weight: 400 !important;
		}

		h4 {
			color: #555;
		}

		.hint {
			color: #999;
			padding: 0;
			margin: 15px 0 7px 0;
		}

		.login-form,
		.forget-form {
			padding: 0px;
			margin:0px;
		}

		.form-control {
			border: none;
			background-color: #dde3ec;
			height: 43px; 
			color: #8290a3;
			border: 1px solid #dde3ec;

			&:focus,
			&:active {
				border: 1px solid #c3ccda;
			}

			@include placeholder(#8290a3);
		}

		select.form-control {
			padding-left: 9px;
			padding-right: 9px;
		}

		.form-title {
			font-weight: 300;
			margin-bottom: 25px;
		}

		.form-actions {
			clear: both;
			border: 0px;
			border-bottom: 1px solid #eee;
			padding: 0px 30px 25px 30px;
			margin-left: -30px;
			margin-right: -30px;
		}
	}

	@media (max-width: 440px) {


		.logo {
			margin-top: 10px;
		}

		.content {
			width: 280px;
			margin-top: 10px; 
		}

		.content h3 {
			font-size: 22px;
		}

	}



}

