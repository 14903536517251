.logo {

	padding-bottom: 0px !important;

	a {

		display: block;
		width: 100%;

		img {
			width: 100%;
			width: calc(100% - 20px);
			max-width: 320px;
		}
	}
}